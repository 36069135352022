.errorTest {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 40rem;
  align-items: center;
  justify-content: center;
}

.errorTest img {
  width: 50%;
  padding-top: 5rem;
}

.errorTest button {
  width: auto;
  padding: 1rem;
  height: auto;
  border-style: none;
  font-weight: 600;
  border-radius: 0.3rem;
  font-size: 1.2rem;
  background-color: #198033;
  font-size: 0.8rem;
  color: white;
  font-family: 'Raleway', sans-serif;
}

.errorTest button:hover {
  background-color: #18b940;
}

.errorTest h3 {
  padding-top: 10rem;
  text-align: center;
  padding-bottom: 3rem;
}
