.boxContainer {
  margin: 1.5rem;
  box-sizing: border-box;
  flex-wrap: wrap;
  width: 100%;
  /* display: flex; */
  align-items: center;
  justify-content: center;
}

.styleFormsRegistration {
  width: 100%;
  padding: 1.5rem;
  margin-top: 1.5rem;
  display: flex;
  border-radius: 0.5rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ecedf5;
}

.gapTitlebox {
  font-size: 1.5rem;
  padding-top: rem;
}

main {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10rem;
}

label {
  font-size: 1rem;
  font-weight: bold;
  color: #1b0e0e;
  font-family: 'Montserrat' sans-serif;
  display: block;
}

#responsabilidade {
  width: 180%;
}

.forms input {
  width: 100%;
  height: 2.8rem;
  padding-left: 0.5rem;
  color: #adb2d9;
  border-top: none;
  background-color: #ecedf5;
  font-size: 1rem;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #000000;
}

.formIntern {
  width: 50%;
}

.forms {
  padding-top: 1.5rem;
  display: block;
  width: 80%;
}

::placeholder {
  color: #626cb9;
  font-size: 0.8rem;
}

.fieldAttributes {
  width: 15%;
}

.box-responsabilidade,
.box-requisitos {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.positionSeparator {
  text-align: center;
}


.Positionlabel {
  padding-top: 1rem;
}

.containerHourWeek input{
 
  max-width: 50%;
}


.containerHourWeek {
  display: flex;
  align-items: center;
  
}

.atributtesStyles p{
  color: #000000;
}




.positionContentTodoList li {
  justify-content: space-between;
  display: flex;
  color: #198033;
  font-size: 0.9rem;
  font-weight: bold;
}

.positionContentTodoList {
  display: block;
  width: 100%;
}

.position-plus {
  display: flex;
  align-items: flex-end;
}

.position-plus button:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  transition: 0.5s;
}

.header-admin {
  width: 100%;
  height: 4rem;
  background-color: #004d13;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo img {
  width: 8rem;
}

.position-two {
  display: flex;
  flex-direction: column;
}

.width-trash {
  width: 1.1rem;
  height: 1.1rem;
  cursor: pointer;
}

.width-1 {
  width: 100%;
  height: 2.5rem;
}

.position-two button {
  border-style: none;
  background: #ffffff;
}

.position-two button:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  transition: 0.5s;
}

.positionAboutInformation {
  display: flex;
  flex-direction: row;
  background-color: #ecedf5;
}

#responsibility {
  width: 28rem;
}

.buttonAdd button {
  border-style: none;
  background-color: none;
  width: 1rem;
}

.position-plus {
  display: flex;
  align-items: flex-end;
}
.position-plus img:hover {
  background-color: white;
}

.position-two {
  display: flex;
  flex-direction: column;
}

.containerTitleRegistration{
  width: auto;
  display: inline-block;
  padding-bottom: 1rem;
}

.separatorBar {
  height: 0.3rem;
  width: auto;
  background: #47b262;
  border-radius: 10px;
}

.container-title h1 {
  font-size: 1.8rem;
  font-family: 'Montserrat', sans-serif;
}

.listVacanciesCreate {
  padding-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.buttonAdd {
  border-style: none;
  background: #ecedf5;
}

.order {
  display: flex;
  padding-top: 1rem;
  justify-content: space-between;
}

.order button {
  border-style: none;
  background: #ecedf5;
}

.order button:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  transition: 0.5s;
}

.size {
  font-size: 1.3rem;
  color: rgb(51, 140, 73);
  background-color: #ecedf5;
}

.trashIcon {
  font-size: 1rem;
  background-color: #ecedf5;
  color: red;
}

.positionabel {
  padding-top: 1rem;
}

.buttonRegistration {
  width: 80%;
  margin-top: 2rem;
  color: white;
  font-weight: bold;
  font-size: 1rem;
  border-radius: 5px;
  background-color: #338c49;
  height: 3rem;
  border-style: none;
  margin-bottom: 1rem;
}

.button-style button a {
  color: white;
  font-weight: bold;
  font-size: 1rem;
}

.buttonRegistration:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  transition: 0.5s;
}

fieldset {
  border-style: none;
}

@media (max-width: 1024px) {
  form {
    width: 100%;
  }

  main {
    max-width: 100%;
  }

  #main {
    max-width: 100%;
  }

  #responsibility {
    width: 100%;
  }

  input#responsibility {
    width: 100%;
  }

  .box-responsabilidade {
    display: flex;
    justify-content: space-between;
    background-color: red;
    width: 100%;
  }

  .box-responsabilidade input {
    background-color: #198033;
    width: 100%;
  }

  .order {
    max-width: 100%;
    display: flex;
  }

  .button-style button {
    width: 100%;
  }

  .container-title3 {
    padding-top: 0;
    text-align: center;
  }

  #horas {
    padding: 0;
  }
}
