.boxList {
  width: 100%;
  padding: 2rem;
}

.pencilicon {
  border-style: none;
}

.listVacanciesCreate {
  padding-top: 2rem;
}

.styleButtonView button {
  width: 1.5rem;
  height: 1.5rem;
  background-color: white;
  border-style: none;
}



.buttonReturnBox {
  width: 100%;
  height: 3rem;
  display: flex;
  margin-top: 2rem;
  align-items: center;
  padding-left: 2rem;
}

.buttonReturn {
  padding: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 0.9rem;
  border-radius: 5px;
  background-color: #338c49;
  border-style: none;
  font-family: 'Raleway', sans-serif;
  cursor: pointer;
}
.containerTitleResults{
  width: auto;
  display: inline-block;
  padding-bottom: 1rem;
}

.buttonReturn:hover {
  background: #52a366;
}

.styleButtonView img {
  background-color: red;
}

.titleBoxlist {
  width: 100%;
  height: 4rem;
  background-color: #3d9954;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 0 2rem;
  justify-content: space-between;
  border-radius: 0.3rem;
}

table .contentBoxList {
  width: 100%;
  height: 3rem;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
  cursor: pointer;
  border-radius: 0.3rem;
  font-family: 'Montserrat', sans-serif;
}

.btnModal {
  width: 100%;
  background-color: none;
  border-style: none;
}

.contentBox tr {
  background-color: rgb(255, 255, 255);
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.contentBoxList button:hover {
  background-color: #bae5c4a4;
}

.tableVacancies {
  width: 100%;
}

.viewContainer {
  background-color: #ecedf5;
  width: 100%;
  height: auto;
  padding: 1rem 1rem;
}

.contentBoxList td {
  display: flex;
}

.gapTitle {
  padding-left: 0.7rem;
  word-wrap: break-word;
}

.styleDateCandidate {
  width: 100%;
  color: rgb(0, 0, 0);
  font-weight: 600;
}

.styleTitle {
  width: 90%;
  padding-left: 1rem;
}

.styleTitle p {
  color: #333333;
  font-weight: 500;
}

.styleId {
  color: #000000;
  font-weight: 600;
}

.boxContVacancie {
  background-color: rgb(255, 255, 255);
  display: flex;
  width: 100%;
  padding: 0.8rem;
  margin-top: 0.5rem;
  word-wrap: break-word;
  border-radius: 0.3rem;
  cursor: pointer;
}

.boxContVacancie:hover {
  background-color: #bae5c4a4;
}

.textListCandidate {
  font-size: 1.6rem;
}

.dateStyle {
  font-family: 'Montserrat', sans-serif;
  color: black;
  font-weight: 700;
}

.contentBoxList tr {
  border-radius: 0.3rem;

  width: 100%;
}

.contentBox tr td p {
  color: #333333;
}

.titleBoxlist td p {
  color: white;
  font-weight: 600;
  font-size: 1rem;
}

.titleBoxlist td {
  align-items: center;
  display: flex;
}

.styleButtonView button {
  background: none;
}

.bgpath {
  background: none;
}
