.AlertVacanciesNotAvaible {
  width: 100%;
  height: 45rem;
  text-align: center;
}

.AlertVacanciesNotAvaible img {
  width: 30%;
  padding-bottom: 2rem;
}

.AlertVacanciesNotAvaible h1 {
  font-size: 2.5rem;
  padding-bottom: 1rem;
  padding-top: 5rem;
}

.AlertVacanciesNotAvaible h4 {
  font-size: 1.2rem;
  padding-bottom: 2rem;
}
