.txtDialog {
  margin: 1rem 0px;
}

h4 small {
  font-size: 0.8rem;
  color: red;
}

#txtDialog {
  color: #3d9954;
  padding-top: 0.5rem;
}
