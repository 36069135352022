.modalBoxIntern {
  width: 100%;
  min-height: 100vh;
  padding: 0 1rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.boxDescriptionResponsability {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.viewBox {
  overflow-y: auto;
  width: 58%;
  background-color: rgb(255, 255, 255);
  min-height: auto;
  border-radius: 0.2rem;
}

.contentModal {
  background-color: #f2f2f2;
  min-width: 43%;
  min-height: auto;
  padding: 1rem;
  margin-left: 2rem;
  border-radius: 0.4rem;
  padding-bottom: 2rem;
}

.btnExit {
  display: flex;
  justify-content: flex-end;
  background-color: #077923;
  height: 60px;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.btnExit button {
  width: 2.5rem;
  background: none;
  border-style: none;

  padding-right: 3rem;
}

.contentModal h1 {
  color: #808000;
  font-size: 1.5rem;
  padding-bottom: 1rem;
  padding-left: 0px;
}

.contentModal h3 {
  font-weight: bold;
  font-size: 1rem;
  padding-right: 0.3rem;
}

.contentModal p {
  font-weight: normal;
  color: black;
  font-size: 1rem;
}

.positionInformation {
  display: flex;
  align-items: center;
  padding-bottom: 0.8rem;
  font-weight: 500;
}

.positionInformation p {
  color: #333;
}

.note {
  text-align: left;
}

.note p {
  font-size: 1rem;
  color: #333;
}

.pullAttachment {
  width: 100%;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  color: white;
  height: 60px;
  border-style: none;
  background-color: #004d13;
  display: flex;
  align-items: center;
  justify-content: center;
}

img .logo {
  width: 100%;
  height: auto;
}

.buttonModal button:hover {
  background-color: #077923;
}

.titleBoxModal {
  width: 100%;
  height: 3rem;
  display: flex;
  padding-bottom: 2rem;
  align-items: center;
  justify-content: center;
}

.titleBoxModal h2 {
  font-size: 1.3rem;
  border-bottom: 4px solid #47b262;
}

.logo2 {
  display: none;
}

.spaceContent {
  padding-left: 0.7rem;
}

@media (min-width: 1200px) {
  .contentModal {
    max-width: 50%;
  }

  .boxModal {
    width: 100%;
    display: flex;
    border-left: 1px solid #d8d1d1;
    border-right: 1px solid #d8d1d1;
    padding: 1rem;
  }

  .positionModal {
    width: 100%;
  }

  

  .logoposition {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
  }

  .contentModal {
    width: 38%;
  }

  .logoposition img {
    width: 80%;
  }
}
