* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.containerFooter {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 4rem;
  align-items: center;
  height: 6rem;
  background-color: #004d13;
}

.containerFooter p {
  color: white;
  font-weight: bold;
  padding-left: 2rem;
  padding-right: 8rem;
}

.barFooter {
  width: 0.1rem;
  height: 100%;
  background-color: white;
}

.containerSocial {
  display: flex;
}

.logoFooter {
  display: flex;
  align-items: center;
}

.boxSocialMedia {
  box-sizing: border-box;
  display: flex;
  width: 11rem;
  height: 6rem;
  align-items: center;
}

.boxSocialMedia img {
  padding-left: 4rem;
}

.boxSocialMedia:hover {
  background-color: rgb(153, 153, 26);
  height: auto;
}
.infoFooter {
  background-color: #338c49;
  width: 100%;
  padding-left: 4rem;
  padding-bottom: 1rem;
  height: 15rem;
}

.infoFooter p {
  padding: 0.5rem;
  color: white;
}

.infoFooter a {
  display: flex;
  align-items: center;
}

.infoFooter h4:first-child {
  padding-top: 2rem;
  margin: 0;
}

.infoFooter p:hover {
  color: rgb(153, 153, 26);
}

.infoFooter img {
  width: auto;
}

.infoFooter h5 {
  color: white;
}

.FooterMobile {
  display: none;
}

@media (max-width: 1024px) {
  .containerFooter {
    display: flex;
    height: 6rem;
    align-items: center;
    justify-content: space-between;
    padding-left: 1rem;
  }

  .infoFooter {
    height: auto;
    padding-left: 0;
  }

  .boxSocialMedia {
    display: none;
  }

  .infoFooter h4 {
    display: none;
  }

  .logoFooter img {
    width: 10rem;
  }

  .logoFooter p {
    font-size: 1rem;
    padding: 0;
    padding-left: 2rem;
  }

  .FooterMobile {
    display: block;
  }

  .FooterMobile a {
    color: white;
    font-weight: bold;
    padding-top: 1rem;
    padding-left: 1rem;
  }

  .infoFooter h5 {
    padding-top: 2rem;
    padding-left: 1rem;
  }
}
