.imageCollaborator img {
  width: 100%;
  padding-left: 5rem;
}

.styleAbout p {
  font-size: 1rem;
}

.boxTextMission {
  width: 40%;
}

.aboutCompany {
  display: flex;
  justify-content: space-between;
}



.missionBox {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}



.vacancieBox {
  padding: 3rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0px 1.5rem;
  
}

h4 {
  margin-bottom: 0.5rem;
}

p {
  color: #7d83b2;
}

.vacanciesContent a {
  font-size: 0.8rem;
  color: #715d5d;
  text-decoration: underline;
  font-weight: bold;
}

.vacanciesContent a:hover {
  color: #198033;
}


.separatorBar{
  height: 0.3rem;
  background: #47b262;
  border-radius: 10px;
}

.containerTitle {
  width: auto;
  display: inline-block;
  padding-bottom: 1.5rem;
  padding-top: 1rem;
}

.separatorBar {
  height: 0.3rem;
  background: #47b262;
  border-radius: 10px;
}

.containerTitle {
  width: auto;
  display: inline-block;
  margin-top: 2rem;
}

.containerTitle p {
  font-size: 1.6rem;
  font-weight: 600;
  color: #222222;
}

.containerTitle p {
  font-size: 2rem;
  font-weight: 600;
  color: #222222;
}

.heightBox {
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.grid {
  padding: 4rem 8rem;
}

.vacancie {
  margin: 1.2rem;
}

.vacancie p {
  font-size: 0.75rem;
  color: #7d83b2;
  font-family: 'Raleway' sans-serif;
}



.buttonApplyVacancie {
  font-size: 0.9rem;
  font-family: 'Raleway', sans-serif;
  border-style: none;
  border-radius: 0.3rem;
  color: white;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  background: #338c49;
}

.buttonApplyVacancie a {
  color: white;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
}

.buttonApplyVacancie a:hover {
  color: white;
}

.buttonApplyVacancie:hover {
  background-color: #31b351;
}

.containerVacanciesBox {
  padding-top: 2.5rem;
}

.buttonApplyVacancie {
  margin-top: 0.6rem;
  width: 100%;
  height: 2.2rem;
}

.positionCenter {
  padding-top: 6rem;
  display: flex;
  flex-direction: column;
}

.vacanciesContent {
  width: 15rem;
  height: auto;
  margin-right: 2.5rem;
  border-radius: 0.6rem;
  margin-bottom: 2rem;
  margin-top: 0.8rem;
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px,
    rgba(19, 18, 18, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px,
    rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px,
    rgba(0, 0, 0, 0.07) 0px 32px 64px;

  cursor: pointer;
}

.vacanciesContent:hover {
  background-color: rgba(0, 0, 0, 0.07);

  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  transform: 0.5s ease 0s;
  transition: 0.5s;
}

.vacanciesContent:last-child {
  margin-right: 0px;
}

@media (min-width: 330px) and (max-width: 767px) {
  .containerTitle p {
    font-size: 1.4rem;
    font-weight: 600;
    color: #222222;
  }

  .containerVacanciesBox {
    padding-top: 1rem;
  }

  .containerTitle{
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .positionCenter {
    padding-top: 1rem;
    
  }

  .missionBox {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .missionBox img{
    
    width: 100%;
  }

  

  .aboutCompany {
    padding: 0 2rem;
  }

  .imageCollaborator {
    padding-top: 3rem;
    width: 100%;
  }

  .styleAbout p {
    width: 100%;
  }

  .vacancieBox {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .vacanciesContent {
    margin-right: 0;
    width: 20rem;
    min-height: 225px;
  }

  .vacanciesContent h4 {
    font-size: 1.2rem;
  }

  .buttonApplyVacancie {
    height: 2.7rem;
  }

  .buttonApplyVacancie a {
    font-size: 1rem;
  }

  .boxTextMission {
    width: 100%;
  }
}

@media (min-width: 280px) and (max-width: 321px) {
  .missionBox {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .aboutCompany {
    padding: 0 1rem;
  }

  .imageCollaborator img {
    padding-top: 3rem;
    width: 100%;
  }

  .styleAbout p {
    width: 60%;
  }

  img {
    width: 100%;
  }
  .grid {
    padding: 0;
  }

  .vacancieBox {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .vacanciesContent {
    margin-right: 0;
    width: 20rem;
  }

  .vacanciesContent h4 {
    font-size: 1.2rem;
  }

  .buttonApplyVacancie {
    height: 2.7rem;
  }

  .buttonApplyVacancie a {
    font-size: 1rem;
  }

  .boxTextMission {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .missionBox {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .aboutCompany {
    padding: 0 2rem;
  }

  .imageCollaborator img {
    padding-top: 3rem;
    width: 100%;
  }

  .styleAbout p {
    width: 60%;
  }

  .grid {
    padding: 0;
  }

  .vacancieBox {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .vacanciesContent {
    margin-right: 0;
    width: 20rem;
  }

  .vacanciesContent h4 {
    font-size: 1.2rem;
  }

  .buttonApplyVacancie {
    height: 2.7rem;
  }

  .buttonApplyVacancie a {
    font-size: 1rem;
  }

  .boxTextMission {
    width: 100%;
  }

  .styleTextMission p {
    width: 100%;
    
  }

  .styleAbout p {
    padding: 0;
  }

  .boxTextMission p {
    width: 100%;
    font-size: 1.3rem;
  }

  .vacanciesContent {
    margin-right: 2.5rem;
    width: 100%;

    margin-right: 0;
  }

  .vacancieBox {
    padding: 0 3rem;
  }
}
