
.filterContainer {
  padding: 2rem;
  max-width: 100%;
  max-height: 100vh;
  overflow-x: auto;
}

.filterContainer a {
  color: #333333;
}

.filterContainer a:hover {
  color: #333333;
}

.InputSearch {
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0.8rem;
  border-radius: 0.3rem;
  width: 60%;
  height: 3rem;
  margin: 0;
}

.filterBox {
  max-width: 100%;
  max-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.inputVacancies {
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputVacancies label {
  padding-right: 2rem;
  font-size: 1.2rem;
}

.filterCard {
  width: 15rem;
  height: 15rem;
  margin: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 0.6rem;
  /* background-color: #d5e5a3; */
  background-color: #effbe7;
  box-shadow: 0 2px 2px #ccc5b980;
  display: flex;
  transition: color var(--fast) var(--ease-out);
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.125);
  cursor: pointer;
}

.filterCard:hover svg {
  fill: #ffffff;
}

.filterCard:hover {
  background-color: #73e02e;
  color: white;
}

.filterCard:hover p {
  color: white;
}

.filterCard:hover small {
  color: white;
}

.boxIconCard {
 
  width: 100%;
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
}

.filterCard p {
  
  color: #73e02e;
  font-family: 'Montserrat', sans-serif;
  font-size: 0.8rem;
  padding: 0.5rem;
  font-weight: 600;
  line-height: 1.2em;
}

.filterCard small {
  color: #60773d;
}

