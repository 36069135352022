@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Raleway&display=swap');

:root {
  --main-bg-color-icon: #004d13;
  --title-color-primary: #808000;
  --title-h2-header: #9f9f9f;
  --color-text: black;
  --background-color: white;
  --bg-header-ligth: #ecedf5;

  --font-Title: Montserrat, Verdana, Helvetica, sans-serif;
  --font-body: Raleway, cursive;
}

.headerBox {
  display: flex;
  align-items: center;
  font-family: var(--font-Title);
  background-color: var(--bg-header-ligth);
  width: 100%;
  padding: 0 1rem;
  height: 5rem;
}
.titleHeaderBox h2 {
  color: var(--title-h2-header);
  font-weight: bold;

  font-size: 1rem;
}

section.grid {
  display: flex;
  flex-direction: column;
  /* padding-bottom: 5rem; */
}

.titleHeaderBox {
  color: var(--title-color-primary);
  font-weight: bold;
}

.titleVacancies h2 {
  display: flex;
  align-items: flex-end;
  font-size: 1.1rem;
  color: var(--title-color-primary);

  letter-spacing: -0.5px;
}

.titleEmail h3 {
  font-size: 1rem;
  display: flex;
  color: var(--title-h2-header);
}

.titleEmail img {
  max-width: 1.3rem;
  margin: 0 1rem;
  display: inline-flex;
  justify-content: space-between;
}

.grid {
  padding: 0 1rem;
  align-items: center;
}

.titleEmail {
  display: flex;
  justify-content: space-between;
}

.logoBoxEmail {
  display: flex;
  width: 3rem;
}

.menuBar {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #3d9954;
  width: 100%;
  height: 4.5rem;
  border-radius: 0.4rem;
  padding: 0 1rem;
}

.menuBar button {
  background: none;
  border-style: none;
  color: var(--background-color);
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  cursor: pointer;
}

#btnfixed {
  border-style: none;
  width: auto;
  padding: 0 1rem;
  height: 2.5rem;
  background-color: var(--background-color);
  border-radius: 0.4rem;
  color: #808000;
  cursor: pointer;
}

.containerVacancies {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.containerVacancies img {
  width: 2rem;
  height: auto;
  display: flex;
  justify-content: flex-start;
}
.boxContainervacancies {
  padding-top: 1rem;
  background-color: var(--background-color);
  width: 100%;

  display: flex;
  align-items: center;
}

.boxTimeDate {
  padding-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  font-family: Arial, Helvetica, sans-serif;
}
.titleEmail p {
  font-size: 0.8rem;
  color: var(--color-text);
  font-family: var(--font-body);
}

.positionBox {
  background-color: var(--bg-header-ligth);
  width: 70rem;
  border-radius: 0.4rem;
  padding: 0.5rem;
  cursor: pointer;
}

.positionBox p {
  color: var(--color-text);
  font-size: 0.9rem;
  font-weight: bold;
}

.btnStyleModal {
  background-color: none;
  border-style: none;
}

.pagination {
  width: 100%;
  height: 3rem;
  display: flex;
  padding: 0rem 2.5rem;
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
  align-items: center;
  justify-content: flex-end;
}

@media (min-width: 321px) and (max-width: 400px) {
  .menuBar button {
    font-size: 0.8rem;
  }
}

@media (max-width: 320px) {
  .menuBar button {
    font-size: 0.6rem;
  }
}
