.admUsers {
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.boxPerfil {
  width: 40%;
  background-color: #ecedf5;
  padding: 3rem 2rem;
  text-align: center;
  margin-left: 1.5rem;
  border-radius: 0.3rem;
  height: 36rem;
}

.boxPerfil input {
  padding-left: 0.5rem;
}

.changePassword {
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 3rem;
}
.changePassword a {
  border-bottom: 1px solid #004d13;
  color: #004d13;
  font-weight: 600;
  padding-left: 0.2rem;
}
.modelBox {
  width: 40%;
  display: flex;
  align-items: center;
  padding-top: 2rem;
  flex-direction: column;
  margin-right: 1.5rem;
  height: 36rem;
  border-radius: 0.3rem;
  background-color: #ecedf5;
}

.iconUser {
  width: 12.5rem;
  height: 12.5rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #c4c4c4;
}

.iconUser img {
  object-fit: cover;
}

.userInfoBox {
  padding-top: 2em;
  text-align: center;
}

.buttonUserInfo {
  border-style: none;
  background-color: #004d13;
  width: 9.4rem;
  height: 2.8rem;
  color: white;
  font-size: 1rem;
  font-weight: 500;
  margin: 2rem auto;
  border-radius: 0.3rem;
}

.styleLabelLoguin {
  padding-top: 1rem;
}

.buttonUserInfo:hover {
  background-color: #5ec478;
  transition: 1s;
}

.smallTxt {
  font-size: 1.3rem;
}

.textInfoTitle {
  font-size: 1.2rem;
  color: black;
  font-weight: bold;
}

.infoUser {
  font-size: 1.2rem;
  color: black;
  font-weight: 500;
  padding-left: 0.3rem;
}

.positionInfo {
  display: flex;
  padding-top: 0.5rem;
  align-items: center;
}
