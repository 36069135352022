.boxSuccessRegistration {
  width: 100%;
  height: 100vh;
  display: flex;
  padding-top: 5rem;
  align-items: center;

  flex-direction: column;
}

.boxSuccessRegistration img {
  width: 35%;
  padding-top: 3rem;
}

.boxSuccessRegistration h1 {
  padding-top: 2rem;
}

.boxSuccessRegistration button {
  border-style: none;
  width: auto;
  padding: 0.7rem;
  color: white;
  border-radius: 0.3rem;
  height: auto;
  font-weight: 700;
  font-size: 0.8rem;
  font-family: 'Raleway';
  background-color: #004d13;
}

.boxSuccessRegistration button:hover {
  background-color: #37994f;
  transition: 0.5s;
}
