.boxList {
  width: 100%;
  padding: 2rem;
}

.pencilicon {
  border-style: none;
}

.styleButtonEdit button {
  width: 1.5rem;
  height: 1.5rem;
  background-color: white;
  border-style: none;
}

.containerTitleView{
  width: auto;
    display: inline-block;
}

.separatorBarView{
  background-color: #47b262;
    margin: 0.2rem;
    width: auto;
    height: 0.3rem;
    border-radius: 1rem;
}

.titleboxlist  {
  width: 100%;
  height: 4rem;
  background-color: #3d9954;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 0 2rem;
  justify-content: space-between;
  border-radius: 0.3rem;
  
}

.titleboxlist tbody{
  
  width: 100%;
}

.titleboxlist tr{
  display: flex;
  align-items: center;
  justify-content: space-between;
}



table .contentBoxList {
  width: 100%;
  height: 3rem;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 0px;
  cursor: pointer;
  border-radius: 0.3rem;
  font-family: 'Montserrat', sans-serif;
}

.contentBoxView tr {
  background-color: rgb(255, 255, 255);
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
}

.contentBoxList button:hover {
  background-color: #bae5c4a4;
}

.tableVacancies {
  width: 100%;
}

.viewContainer {
  background-color: #ecedf5;
  width: 100%;
  height: auto;
  padding: 1rem 1rem;
}

.contentBoxList tr {
  display: flex;
}

.gapTitle {
  padding-left: 0.7rem;
  word-wrap: break-word;
}

.styleDate {
  width: 100%;
  padding-left: 3rem;
}

.styleTitle {
  width: 90%;
  padding-left: 1rem;
}

.styleTitle p {
  color: #333333;
  font-weight: 500;
}

.styleId {
  color: #000000;
  font-weight: 600;
}

.boxContVacancies {
  background-color: rgb(255, 255, 255);
  display: flex;
  width: 100%;
  padding: 0.8rem;
  margin-top: 0.5rem;
  word-wrap: break-word;
  border-radius: 0.3rem;
  cursor: pointer;
}

.boxContVacancies:hover {
  background-color: #bae5c4a4;
}

.textSizeView {
  font-size: 1.8rem;
}

.dateStyle {
  font-family: 'Montserrat', sans-serif;
  color: black;
  font-weight: 700;
}

.contentBoxList tr {
  border-radius: 0.3rem;

  width: 100%;
}

.contentBoxView tr td p {
  color: #333333;
}

.titleboxlist td p {
  color: white;
  font-weight: 600;
  font-size: 1rem;
}

.titleboxlist td {
  align-items: center;
  display: flex;
 
}

.styleButtonEdit {
  display: flex;
  justify-content: end;
}

.styleButtonEdit button {
  background: none;
}

.bgpath {
  background: none;
}
