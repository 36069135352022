* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
  font-family: 'Raleway', sans-serif;
}

html {
  scroll-behavior: smooth;
}

h1,
h2,
h3 {
  font-family: 'Montserrat', sans-serif;
}

a {
  text-decoration: none;
}

p {
  font-family: 'Raleway', sans-serif;
}

button {
  cursor: pointer;
}
