.state {
  border: 1px solid #0d1133;
  border-radius: 0.6rem;
  cursor: pointer;
}

.containerCollege {

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  

}


.containerVacantMenu{
  padding: 1rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0px 1.5rem;
}

select {
  width: 100%;
  padding: 0.4rem;
  margin-bottom: 1rem;
  height: 2.5rem;
  color: #000000;
  border-radius: 0.3rem;
}

.fileStyle:hover {
  color: #18b940;
  border-color: #1cb843;
}

details summary {
  list-style: none;
}

label {
  color: #222222;
  font-weight: bold;
  display: flex;
  align-items: center;
}
input {
  width: 100%;
  margin-bottom: 0.5rem;
  height: 2.5rem;
  color: #adb2d9;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #222222;
  outline: none;
}

.file {
  border-style: none;
  padding-top: 0.3rem;
}

.fileStyle {
  align-items: center;
  justify-content: center;
  border-radius: 0.3rem;
  display: flex;
  width: 100%;
  height: 3rem;
  border: 0.06rem dashed #198033;
  color: #adb2d9;
  font: 14px 'Montserrat', 'Raleway', sans-serif;
  cursor: pointer;
}

textarea {
  align-items: center;
  justify-content: center;
  border-radius: 0.4rem;
  display: flex;
  width: 100%;
  height: 6rem;
  border: 0.06rem solid #222222;
  color: #7d83b2;
  font-size: 0.9rem 'Montserrat', 'Raleway', sans-serif;
  padding: 0.4rem;
}

.red {
  color: red;
  font-size: 1.8rem;
  font-weight: 500;
  padding-right: 0.1rem;
}

.closedCollapse{
  background-color: red;
}

.positionContent {
  font-size: 1.8rem;
  color: #222222;
  padding-top: 1rem;
  font-weight: 500;
}

fieldset {
  border: none;
}

.actionAlert img {
  width: 100%;
  height: auto;
}

.positionTitle {
  width: auto;
  display: inline-block;
  margin: 1.5rem 0;
 
}
.titleSize {
  font-size: 1.8rem;
  font-weight: 600;
  color: #222222;
}

.separatorBar {
  background-color: #47b262;
  width: auto;
  height: 0.3rem;
  border-radius: 1rem;
}

select:hover,
select:focus,
select:active,
select:focus {
  transition: 0s;
  outline-color: red;
}
.inputCnhStyle {
  margin-top: 0.3rem;
  width: 30%;
}

.container{
 
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  margin: 1rem 9rem;

}

.inputRadius {
  display: flex;
  height: 2rem;
  align-items: center;
  margin: 0.7rem 0rem;
}

.buttonOnSave {
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonOnSave button {
  background-color: #198033;
  padding: 0.7rem 2rem;
  border-style: none;
  border-radius: 0.3rem;
  color: white;
  font-weight: 500;
  font-family: 'Raleway', sans-serif;
  margin-bottom: 2rem;
}

.ContLanguage{
  margin-top: 1rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.courseContent {
  width: 100%;
  padding-right: 1rem;
  
}

.teste {
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  
}

.teste select {
 
  max-width: 100%;
}
.fieldSchoolFormation{

  width: 100%;
  padding-right: 1rem;
 
}

.fieldSchoolStatus {
  
  width: 100%;
  padding-left: 1.2rem;
}

.containerDescriptionResponsability {
  padding-top: 1.2rem;
}

.containerDescriptionResponsability label {

padding-bottom: 0.6rem;
}


.fieldSchool select{
  width: 100%;
  
}

.courseInstitution{
  width: 100%;
  padding-left: 1rem;
}

.sizeField {
  width: 45%;
  
}

.inputYes {
  height: 2rem;
  display: flex;
  align-items: center;
  margin: 0;
  padding-right: 0.5rem;
}

.inputRadius input {
  width: 1rem;
  margin: 0;
}

.inputRadius label {
  padding-left: 0.5rem;
}

.containerJobs {
  width: 35rem;
  height: 42rem;
  border-radius: 0.6rem;
  box-shadow: 1px 1px 3px 2px #7d83b2;
  padding: 1.6rem;
  margin-right: 3rem;
  margin-top: 3rem;
}

.details {
  list-style: none;
}
.summary {
  list-style: none;
}

.titleCollapse {
  cursor: pointer;
  font-weight: 600;
  height: 3.5rem;
  padding: 1rem;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}



details[open] .titleCollapse:before {
  content: 'Salvar';
  position: absolute;
  right: 16px;
  transform: translatey(-50%);
  top: 50%;
  background-color: #18b940;
  width: 6rem;
  text-align: center;
  border-radius: 0.3rem;
  color: white;
  font-weight: 500;
  cursor: pointer;
  padding: 0.5rem;
}

.containerCandidate {
  display: flex;
  justify-content: space-between;
  width: 100%;
 
}
.containerState {
 
  width: 50%;
}

.lina {
 
  min-width: 70%;
}

.containerCandidate select {
  width: 85%;
  height: 50px;
  text-align: center;
  border: 1px solid #d9d9d9;
  outline-color: #4173df;
}

.containerCandidate select::placeholder {
  color: #d9d9d9;
}

.boxCollapse{
 
  padding: 1rem;
}

.collapseContainer {
  border: 1px solid #333333;
  border-radius: 4px;
  margin: 1rem 0rem;
  max-width: 500px;
  transition: background-color 0.25s;
  will-change: background-color;
  
}

.contentCollapse {
  font-size: 14px;
  line-height: 21px;
  
}


.formCandidate {
  margin-left: 3rem;
  width: 31rem;
  height: auto;
}

.positionCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tipografia {
  font-size: 0.8rem;
  width: 30rem;
  height: auto;
  flex-wrap: wrap;
}

li {
  font-size: 0.8rem;
}

.socialNetworks {
  font-size: 0.8rem;
  display: block;
}

.socialNetworks h4 {
  font-size: 1rem;
  padding-top: 2rem;
  padding-bottom: 1rem;
}

.socialNetworks a {
  font-size: 0.8rem;
  border-bottom: 1px solid #66cc80;
  align-items: center;
  margin-right: 3rem;
  color: #66cc80;
  font-weight: bold;
}

.socialNetworks a:hover {
  color: #5ab370;
  border-bottom: 2px solid #66cc80;
}

.responsibility h4 {
  font-size: 1rem;
  padding-top: 2rem;
}

.requirements {
  font-size: 1rem;
  padding-top: 2rem;
}

.positionQualification {
  margin-left: 2rem;
  font-size: 0.9rem;
  color: #7d83b2;
}

.buttonSubmit {
  width: 100%;
  height: 3.4rem;
  margin-top: 1rem;
  margin-bottom: 5rem;
  border-style: none;
  background-color: #198033;
  font-size: 0.9rem;
  border-radius: 0.3rem;
  color: #ffffff;
  font-weight: bold;
  cursor: pointer;
}

.buttonSubmit:hover {
  background-color: #31b351;
  /* -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  transition: 0.5s; */
}

::-webkit-input-placeholder {
  color: #7d83b2;
  font: 0.9rem 'Raleway', sans-serif;
}

@media (min-width: 330px) and (max-width: 767px) {
  .titleSize p {
    font-size: 1.8rem;
    font-weight: 600;
    color: #e91919;
  }

  .containerJobs {
    box-shadow: none;
    display: flex;
    flex-direction: column;
  }

  .tipografia {
    padding-top: 2rem;
    width: 100%;
  }

  .tipografia p {
    padding-bottom: 1rem;
  }

  .tipografia {
    display: flex;
    width: 30rem;
    padding-top: 0;
  }

  .elements {
    flex-direction: column;
    width: 100%;
  }

  .containerJobs {
    padding: 0;
    margin: 0;
    width: 100%;
    height: auto;
  }

  .formCandidate {
    margin: 0;
    width: 100%;
    height: auto;
  }

  .socialNetworks a {
    margin-right: 1rem;
    font-size: 0.7rem;
  }

  .containerVacantMenu {
    padding: 0 2rem;
    margin: 0;
    flex-direction: column;
    
  }

  .aboutVacancies {
    width: 100%;
  }

  .PositionTitle p {
    font-size: 2rem;
    font-weight: 500;
    color: #222222;
  }

  .responsibility h4 {
    font-size: 1.1rem;
  }

  .responsibility li {
    font-size: 1rem;
  }

  .requirements h4 {
    font-size: 1.1rem;
  }

  .requirements li {
    font-size: 1rem;
  }

  .socialNetworks {
    display: none;
  }
}

@media (min-width: 280px) and (max-width: 329px) {
  .containerJobs {
    box-shadow: none;
    display: flex;
    flex-direction: column;
  }

  .tipografia {
    padding-top: 2rem;
    width: 100%;
  }

  .tipografia p {
    padding-bottom: 1rem;
  }

  #root {
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .tipografia {
    display: flex;
    width: 30rem;
    padding-top: 0;
  }

  .elements {
    flex-direction: column;
    width: 100%;
  }

  .containerJobs {
    padding: 0;
    margin: 0;
    width: 100%;
    height: auto;
  }

  .formCandidate {
    margin: 0;
    width: 100%;
    height: auto;
  }

  .socialNetworks a {
    margin-right: 1rem;
    font-size: 0.7rem;
  }

  .container {
    padding: 0 1rem;
    margin: 0;
  }

  .aboutVacancies {
    width: 100%;
  }

  .PositionTitle h2 {
    font-size: 1.4rem;
  }

  .responsibility h4 {
    font-size: 1.1rem;
  }

  .responsibility li {
    font-size: 1rem;
  }

  .requirements h4 {
    font-size: 1.1rem;
  }

  .requirements li {
    font-size: 1rem;
  }

  .socialNetworks {
    display: none;
  }

  .grid {
    margin: 0 1rem;
  }

  .positionCenter h2 {
    font-size: 1.1rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .elements {
    flex-direction: column;
    padding: 0 2rem;
  }

  .containerJobs {
    width: 50rem;
    margin-right: 0;
    margin-bottom: 3rem;
  }

  .positionAbout {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .formCandidate {
    width: 80%;
  }

  .socialNetworks {
    display: none;
  }

  .responsibility h4 {
    font-size: 1.5rem;
  }

  .responsibility li,
  .requirements li {
    font-size: 1rem;
  }

  .requirements {
    font-size: 1.5rem;
    padding-top: 5rem;
  }
}
