* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.App-header {
  width: 100%;
  height: 4.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  background-color: #004d13;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5rem;
  box-sizing: border-box;
  font-family: 'Raleway', sans-serif;
}

.App-header p {
  color: white;
  font-size: 1.2rem;
  padding-left: 2.5rem;
}

#logo {
  width: 8rem;
}

@media (min-width: 330px) and (max-width: 800px) {
  .App-header {
    width: 100%;
    height: 4.5rem;
    background-color: #004d13;
    display: flex;
    align-items: center;
    padding: 0.3rem 0.8rem;
    justify-content: space-between;
    font-family: 'Raleway', sans-serif;
  }

  .App-header {
    display: flex;
    justify-content: space-between;
  }
  .App-header h2 {
    font-size: 0.8rem;
  }

  #logo {
    width: 10rem;
  }

  .App-nav p {
    font-size: 0.8rem;
    width: 10rem;
  }
}

@media (min-width: 280px) and (max-width: 330px) {
  .App-header h2 {
    font-size: 0.8rem;
  }

  .App-header {
    display: flex;
    flex-direction: column;
    height: 6rem;
  }

  #logo {
    width: 7rem;
  }
}
