.boxConfirmation {
  width: 100%;
  height: 44rem;
  background-color: #0e0e0e;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.9;
}

.modalSummary {
  background-color: #ffffff;
  width: 50%;
  height: 30rem;
  border-radius: 0.5rem;
  text-align: center;
}

.container {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
}

.headerConfirmation {
  background-color: rgb(242, 242, 242);
  padding: 1rem 3rem 0rem;
  height: 5.5rem;
  margin-bottom: 45px;
}

.logoConfirmation {
  border: 1px solid #cec1c1;
  border-radius: 4px;
  width: 9rem;
  height: 9rem;
  position: relative;
  background: rgb(255, 255, 255);
  margin-top: 24px;
}

.logoConfirmation img {
  padding: 1rem;
}

.containerConfirmation {
  padding-top: 0rem;
  text-align: center;
  padding-left: 2rem;
}

.titleDone p {
  font-size: 1.2rem;
  color: #000000;
  font-weight: 600;
}

.resumeDone {
  padding: 2rem;
  line-height: 1.5rem;
  text-align: center;
}

.resumeDone p {
  color: rgb(68, 58, 58);
}

.buttonDone {
  width: 13rem;
  height: 3.5rem;
  background-color: #004d13;
  color: white;
  font-weight: 700;
  font-size: 0.8rem;
  font-family: 'Raleway', sans-serif;
  text-align: center;
  border-radius: 0.3rem;
  border-style: none;
}

.buttonDone:hover {
  background-color: #198033;
}

.positionTitle {
  width: auto;
  display: inline-block;
}

.separatorBar {
  background-color: #47b262;
  margin: 0.2rem;
  width: auto;
  height: 0.3rem;
  border-radius: 1rem;
}
