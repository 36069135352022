.boxAlerts {
  width: 100%;
  height: 25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.boxAlerts p {
  font-size: 1.5rem;
  font-weight: 700;
  color: black;
  padding-top: 0.8rem;
  border-bottom: 5px solid #8bc34a;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.FcApproval {
  font-size: 5rem;
}
