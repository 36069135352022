.dashboardContent {
  width: 16rem;
  min-height: 100vh;
  background-color: #004d13;
}

.dashboardContent a {
  display: flex;
  padding: 1rem;
  align-items: center;
}

.outCenter {
  display: flex;
  align-items: center;
}

.dashboardContent li a {
  display: flex;
  align-items: center;
}

.dashboardContent li p {
  padding-left: 0.3rem;
  color: white;
}

.containerRoutes {
  flex: 1;
  padding-top: 0;
}

.containerRoutes h1 {
  text-align: center;
}

.dashContainer {
  width: 100%;
  display: flex;
}

.dashboardContent a {
  font-size: 1rem;
  padding-left: 1rem;
  color: white;
  font-weight: 600;
  cursor: pointer;
}

.dashboardContent a:hover {
  cursor: pointer;
}

.isActive {
  width: 100%;
  height: 2.8rem;
  display: flex;
  text-align: center;
  align-items: center;
  background-color: rgba(236, 226, 226, 0.486);
}

.isActive a {
  color: white;
}

.dashboardContent ul {
  padding-top: 4rem;
}

.outDashbord {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: 12vw;
}

.logoDashboard {
  width: 100%;
  padding-top: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logoDashboard img {
  width: 10rem;
}
