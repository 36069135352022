.containerLogin {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: rgb(240, 242, 245);
}

.ErrorStyle {
  color: red;
  font-weight: 600;
}

.ErrorStyleAlert {
  color: rgb(255, 0, 0);
  width: 100%;
  height: auto;
  font-weight: 600;
  animation: go-back 1s;
}

.ErrorStyleAlertLogin {
  color: rgb(255, 0, 0);
  width: 100%;
  height: auto;
  font-weight: 600;
}

.boxLogin {
  display: flex;
  -webkit-box-align: stretch;
  align-items: stretch;
  width: 100%;
  max-width: 800px;
  margin: 15px;
}

.loginLogo img {
  width: 55%;
}

.loginLogo {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column;
  color: rgb(255, 255, 255);
  background-image: linear-gradient(45deg, rgb(34, 115, 55), rgb(0, 77, 19));
  background-color: #004d13;
  padding: 15px;
  z-index: 0;
  animation: 2s ease-out 0s 1 normal forwards running animationLogo;
  flex: 1 1 0%;
  box-shadow: rgb(187 165 165) 0px 0px 18px;
}

.containerLoginEnter {
  padding: 20px 30px;
  background-color: rgb(255, 255, 255);
  z-index: 1;
  box-sizing: border-box;
  flex: 1.2 1 0%;
  box-shadow: rgb(187 165 165) 0px 0px 18px;
  height: 100%;
}

.containerLoginEnter h2 {
  text-align: center;
  font-size: 1.8rem;
}

.buttonLogin {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 700;
}

.formField {
  color: #ff0000;
  font-weight: 600;
  font-size: 0.8rem;
}

.buttonLogin img {
  padding-left: 0.5rem;
}

.posLabel {
  width: 100%;
}

.positionEye {
  display: flex;
  width: 100%;
}

.starRed{
  color: red;
    font-size: 1.8rem;
    font-weight: 500;
    padding-right: 0.1rem;
}

.styleLabelLogin input {
  border-top: 1px solid #c5bcbc;
  border-left: 1px solid #c5bcbc;
  border-right: 1px solid #c5bcbc;
  border-bottom: 1px solid #c5bcbc;
  border-radius: 0.1rem;
  padding-left: 0.8rem;
  color: #333333;
  margin-bottom: 1px;
}

@keyframes animationLogo {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0px);
  }
}

@keyframes hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.styleLabelLogin button {
  background-color: #004d13;
  width: 100%;
  margin-top: 0.3rem;
  height: 2.7rem;
  border-style: none;
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.styleLabelLogin button a {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;
  color: white;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  font-weight: 600;
}

.styleLabelLogin label {
  padding: 0.3rem;
}

.styleLabelLogin button:hover {
  background-color: #5ec478;
  transition: 1s;
}

.loginTitle {
  font-size: 1.4em;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  color: white;
  font-weight: 600;
}

.SaveIcon {
  color: red;
}

.buttonSubmitLogin {
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 2px;
}

.positionIcon {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.positionIcon p {
  color: white;
  font-weight: 600;
  padding-right: 0.4rem;
  font-size: 0.9rem;
}

.buttonSubmitLogin:disabled {
  opacity: 0.5;
}
